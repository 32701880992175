import CustomCard from "../shared/CustomCard";
import NextEventView from "./NextEventView";
import React, {useState} from "react";
import {Box, Dialog, DialogTitle, IconButton, Stack, Typography} from "@mui/material";
import {ArrowBackIos} from "@mui/icons-material";
import AgendaView from "./AgendaView";

const Agenda = ({agendaContent}) => {
    const [openAgenda, setOpenAgenda] = useState(false)

    function handleToggleAgenda() {
        setOpenAgenda(!openAgenda)
    }

    return (
        <Stack>
            <Box onClick={agendaContent.length > 0 ? handleToggleAgenda : null}>
                <CustomCard agenda
                            children={<NextEventView agenda={agendaContent}/>}/>
            </Box>
            <Dialog fullScreen open={openAgenda} onClose={handleToggleAgenda} keepMounted={false}>
                <DialogTitle>
                    <IconButton onClick={handleToggleAgenda}>
                        <ArrowBackIos/>
                    </IconButton>
                    <Typography textAlign='center' variant='h5'>Agenda</Typography>
                </DialogTitle>
                <AgendaView agenda={agendaContent}/>
            </Dialog>
        </Stack>
    );
}

export default Agenda;