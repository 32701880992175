import React, {useEffect, useState} from 'react';
import {Box, Chip, Container, Grid, Paper, Stack, Typography} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';
import NextPlanIcon from '@mui/icons-material/NextPlan';
import pleshDark from "../../theme/pleshDark";

const NextEventView = ({agenda}) => {
    const [displayEvent, setDisplayEvent] = useState(null);

    useEffect(() => {
        const findCurrentOrNextEvent = (now, formattedTime) => {
            // Helper functions
            const parseTime = (timeStr) => {
                const [hours, minutes] = timeStr.split(':').map(Number);
                return hours * 60 + minutes;
            };

            const isTimeInRange = (startTime, endTime, currentTime) => {
                const currentMinutes = parseTime(currentTime);
                const startMinutes = parseTime(startTime);
                const endMinutes = parseTime(endTime);
                return currentMinutes >= startMinutes && currentMinutes <= endMinutes;
            };

            const isUpcomingEvent = (startTime, currentTime) => {
                const currentMinutes = parseTime(currentTime);
                const startMinutes = parseTime(startTime);
                return startMinutes > currentMinutes;
            };

            // Trova l'evento corrente o il prossimo evento
            let targetEvent = null;
            const todayDate = now.toISOString().split('T')[0];

            // Ordina i giorni per data
            const sortedDays = [...agenda].sort((a, b) =>
                new Date(a.date).getTime() - new Date(b.date).getTime()
            );

            for (const day of sortedDays) {
                const eventDate = new Date(day.date);
                const isToday = eventDate.toISOString().split('T')[0] === todayDate;
                const isFutureDay = eventDate > now;

                // Se è un giorno passato, salta
                if (eventDate < now && !isToday) continue;

                // Ordina le fasce orarie per ora di inizio
                const sortedTimeSlots = day.timeSlots.sort((a, b) => {
                    return parseTime(a.startTime) - parseTime(b.startTime);
                });

                for (const timeSlot of sortedTimeSlots) {
                    // Per il giorno corrente, controlla se l'evento è in corso o futuro
                    if (isToday) {
                        const isCurrentTimeSlot = isTimeInRange(timeSlot.startTime, timeSlot.endTime, formattedTime);
                        const isUpcomingTimeSlot = isUpcomingEvent(timeSlot.startTime, formattedTime);

                        if (isCurrentTimeSlot) {
                            targetEvent = {
                                day: day,
                                timeSlot: timeSlot,
                                status: 'current'
                            };
                            break;
                        } else if (isUpcomingTimeSlot && !targetEvent) {
                            targetEvent = {
                                day: day,
                                timeSlot: timeSlot,
                                status: 'upcoming'
                            };
                            break;
                        }
                    }
                    // Per i giorni futuri, prendi il primo evento disponibile
                    else if (isFutureDay && !targetEvent) {
                        targetEvent = {
                            day: day,
                            timeSlot: timeSlot,
                            status: 'upcoming'
                        };
                        break;
                    }
                }

                if (targetEvent) break;
            }

            return targetEvent;
        };

        const updateEvent = () => {
            console.log('update event');
            const now = new Date();
            const formattedTime = now.toLocaleTimeString('it-IT', {
                hour: '2-digit',
                minute: '2-digit'
            });

            const newEvent = findCurrentOrNextEvent(now, formattedTime);
            setDisplayEvent(newEvent);
        };

        // Aggiorna immediatamente
        updateEvent();

        // Imposta un intervallo per aggiornare ogni minuto (60000 ms)
        const timer = setInterval(updateEvent, 60000);

        // Pulisce l'intervallo quando il componente viene smontato
        return () => clearInterval(timer);
    }, [agenda]); // dipende solo da agenda

    if (!displayEvent) {
        return (
            <Container maxWidth="md">
                <Typography color={'white'} fontWeight={'bold'} variant="body1" align="center" sx={{my: 1}}>
                    Nessun evento programmato
                </Typography>
            </Container>
        );
    }

    return (
        <Stack>
            <Paper
                elevation={3}
                sx={{
                    position: 'relative',
                    p: 2,
                    backgroundColor: pleshDark.palette.background.default,
                }}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12} md={8}>
                        <Box display="flex" alignItems="start" maxHeight={'40px'} overflow={'hidden'}>
                            <EventIcon fontSize='small' sx={{mr: 1, color: 'text.secondary'}}/>
                            <Typography textAlign='left' variant="body2" color="text.secondary">
                                {(new Date(displayEvent.day.date)).toLocaleDateString()}
                                -
                            </Typography>
                            <Typography textAlign='left' variant="body2" color="text.secondary">
                                {displayEvent.day.title}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} maxHeight={'60px'} overflow={'hidden'}>
                        <Stack alignItems="left" justifyContent="left" spacing={1}>
                            <Typography variant="subtitle2" textAlign="left" sx={{wordBreak: "break-all"}}>
                                <Chip
                                    sx={{mr:0.5}}
                                    label={displayEvent.status === 'current' ? 'In corso' : 'Next'}
                                    color={displayEvent.status === 'current' ? 'primary' : 'info'}
                                    size="small"
                                    icon={displayEvent.status === 'current' ? (
                                        <AccessTimeIcon
                                            fontSize='small'
                                            sx={{
                                                mr: 1,
                                                verticalAlign: 'middle',
                                                color: 'primary.main'
                                            }}
                                        />
                                    ) : (
                                        <NextPlanIcon
                                            fontSize='small'
                                            sx={{
                                                mr: 1,
                                                verticalAlign: 'middle',
                                                color: 'info.main'
                                            }}
                                        />
                                    )}
                                />
                                <strong>{displayEvent.timeSlot.startTime} - {displayEvent.timeSlot.endTime}</strong> - {displayEvent.timeSlot.description}
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Paper>
        </Stack>
    );
};

export default NextEventView;