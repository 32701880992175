import React from "react";
import {List, ListItem, styled, Typography} from "@mui/material";
import {THEME_CONFIG} from "../../../config";

const {poll, pollBorder, pollTextColor, borderRadius} = THEME_CONFIG

export default function QuizQuestion({risposte, corretta, hideResult = false, answer, sendAnswer, isPoll}) {
    const RispostaStyled = styled(ListItem)({
        border: `2px solid ${pollBorder.default}`,
        borderRadius: borderRadius.default,
        background: poll.default,
        margin: '1rem 0',
        cursor: 'pointer',
        color: pollTextColor.default
    })

    const computeBkg = (r) => {
        if(!isPoll && hideResult === false) {
            if (answer !== '') {
                if (corretta === r) {
                    return poll.correct
                } else if (answer === r  && answer !== corretta){
                    return poll.wrong
                }
            }
        } else {
            if(answer === r) {
                return poll.answered
            }
        }

    }

    const computeBorderColor = (r) => {
        if(!isPoll && hideResult === false) {
            if (answer !== '') {
                if (corretta === r) {
                    return pollBorder.correct
                } else if (answer === r  && answer !== corretta){
                    return pollBorder.wrong
                }
            }
        } else {
            if(answer === r) {
                return pollBorder.answered
            }
        }
    }

    const computeTextColor = (r) => {
        if(!isPoll && hideResult === false) {
            if (answer !== '') {
                if (corretta === r) {
                    return pollTextColor.correct
                } else if (answer === r  && answer !== corretta){
                    return pollTextColor.wrong
                }
            }
        } else {
            if(answer === r) {
                return pollTextColor.answered
            }
        }
    }

    const Risposte = () => (
        risposte.map((r, i) => (
            <RispostaStyled key={i} style={{
                width: '100%',
                background: computeBkg(r),
                border: computeBorderColor(r) ? '2px solid '+ computeBorderColor(r) : `2px solid ${pollBorder.default}`,
                color: computeTextColor(r) ?  computeTextColor(r) :  pollTextColor.default,
            }} onClick={() => rispondi(r)}>
                <Typography variant={"h6"} fontWeight='400' textAlign={'center'} width={'100%'}>
                    {r}
                </Typography>
            </RispostaStyled>
        ))
    )

    const rispondi = (r) => {
        if(isPoll) {
            sendAnswer(r)
        } else {
            if(answer === ''){
                sendAnswer(r)
            }
        }
    }

    return (
        <List>
            <Risposte/>
        </List>
    )
}
