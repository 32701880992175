import React from "react";
import {Box, Slide, Stack, Typography} from "@mui/material";

import axios from "axios";
import {useParams} from "react-router-dom";
import {emojisplosion} from "emojisplosion";
import {API_URL} from "../config";
import {useMediaQuery} from "@material-ui/core";

const ReactionsLive = ({url, idPlaying, reactions, eventPlaying, user}) => {
    const {id} = useParams()

    const _reactions = reactions || []//['👏','👏','👏','👏',]

    async function postDataApplausi(e, type, emoji) {
        emojisplosion({
            emojiCount:5,
            emojis: [emoji],
            position: () => ({
                x: e.clientX,
                y: e.clientY,
            }),
        })
        await axios.post(`${API_URL}/${id}/reactions?type=${type}`, {user: user.uid})
            .then(r => {
                navigator.vibrate(200)
            })
            .catch(error => console.log(error))
    }

    const ButtonItem = ({type, img, emoji, color}) => (
        <Box id='clapButton' disabled={!eventPlaying} onClick={(e) => postDataApplausi(e, type, emoji)}
             justifyContent={'center'} alignItems={'center'} direction={"column"}
             pt={'12px'}
            //borderRadius={"0 16px"} border={'1.5px solid '+color}
             sx={{
                 //bgcolor: pleshDark.palette.accent.main,//color,
                 width: 70,
                 height: 70,
                 cursor: 'pointer',
                 //boxShadow: '6px 5px 6px 0px #37373761'
             }}
        >
            <img style={{margin: 'auto', height:'75px'}} src={img} />
        </Box>
    )

    const matches = useMediaQuery('(max-height:680px)');

    return (
        <Stack alignItems={'center'} sx={{position: 'relative',}}>
            {/*<Typography gutterBottom variant={'caption'}>Send your reactions</Typography>*/}
            <Typography textAlign={'center'} fontSize={12} fontWeight={'normal'} color={'primary'} >
                INTERAGISCI CON LO SCHERMO DEL PALCO!
            </Typography>

            <Slide in direction={"up"} timeout={1000}>
                <Stack mx={4} mb={2} direction={'row'} display={'flex'} justifyContent={'space-between'} spacing={2}>
                    {
                        _reactions.map((emoji, index) => {
                          if(!!emoji) {
                            return <ButtonItem key={index}
                                               type={index+1}
                                               img={`https://storage.googleapis.com/plesh-bucket-public/reactions-2ndStage/${id}/${index + 1}.png`}
                                               emoji={emoji}/>
                          }
                        })
                    }
                </Stack>
            </Slide>
        </Stack>
    )
}
export default ReactionsLive