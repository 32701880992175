import {styled} from "@mui/material/styles";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Divider,
    List,
    ListItem,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {ExpandMore} from "@mui/icons-material";

const TimeSlot = styled(ListItem)(({theme, isActive}) => ({
    display: 'flex',
    padding: theme.spacing(2),
    backgroundColor: isActive ? theme.palette.primary.lightest : 'transparent',
    borderLeft: isActive ? `4px solid ${theme.palette.primary.main}` : '4px solid transparent',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const StyledAccordion = styled(Accordion)(({theme, isToday}) => ({
    backgroundColor: isToday ? theme.palette.error.lightest : 'transparent',
    '&.Mui-expanded': {
        backgroundColor: isToday ? theme.palette.error.lightest : theme.palette.background.paper,
    },
    '&:before': {
        display: 'none',
    },
    margin: '8px 0 !important',
}));

const StyledAccordionSummary = styled(AccordionSummary)(({theme, isToday}) => ({
    backgroundColor: isToday ? theme.palette.primary.main : theme.palette.secondary.main,
    color: isToday ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText,
    '&.Mui-expanded': {
        backgroundColor: isToday ? theme.palette.primary.main : theme.palette.secondary.main,
    },
}));

const AgendaView = ({agenda}) => {
    const [currentTime, setCurrentTime] = useState(null);
    const [expandedPanels, setExpandedPanels] = useState([]);

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            setCurrentTime(now.toLocaleTimeString('it-IT', {
                hour: '2-digit',
                minute: '2-digit'
            }));
        };

        updateTime();
        const timer = setInterval(updateTime, 60000);

        // Espandi automaticamente tutti i giorni
        setExpandedPanels(agenda.map(day => day.date));

        return () => clearInterval(timer);
    }, [agenda]);

    const isCurrentTimeSlot = (startTime, endTime) => {
        if (!currentTime) return false;

        const parseTime = (timeStr) => {
            const [hours, minutes] = timeStr.split(':').map(Number);
            return hours * 60 + minutes;
        };

        const current = parseTime(currentTime);
        const start = parseTime(startTime);
        const end = parseTime(endTime);

        return current >= start && current <= end;
    };

    const getNextEndTime = (timeSlots, index) => {
        if (index < timeSlots.length - 1) {
            return timeSlots[index + 1].startTime;
        }
        return '23:59';
    };

    const isToday = (date) => {
        const today = new Date().toISOString().split('T')[0];
        return date === today;
    };

    const handleAccordionChange = (date) => (event, isExpanded) => {
        setExpandedPanels(prev =>
            isExpanded
                ? [...prev, date]
                : prev.filter(d => d !== date)
        );
    };

    return (
        <Box sx={{width: '100%', maxWidth: 500, mx: 'auto', p: 2}}>
            <Stack spacing={1}>
                {agenda.map(day => (
                    <StyledAccordion
                        key={day.date}
                        expanded={expandedPanels.includes(day.date)}
                        onChange={handleAccordionChange(day.date)}
                        isToday={isToday(day.date)}
                    >
                        <StyledAccordionSummary
                            expandIcon={<ExpandMore/>}
                            isToday={isToday(day.date)}
                        >
                            <Typography sx={{fontWeight: 'bold'}}>
                                {(new Date(day.date)).toLocaleDateString()} - {day.title}
                            </Typography>
                        </StyledAccordionSummary>
                        <AccordionDetails sx={{p: 0}}>
                            <Paper elevation={0}>
                                <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                                    {day.timeSlots.map((slot, index) => {
                                        const endTime = getNextEndTime(day.timeSlots, index);
                                        const isActive = isToday(day.date) && isCurrentTimeSlot(slot.startTime, endTime);

                                        return (
                                            <>
                                                <TimeSlot key={slot.startTime} isActive={isActive}>
                                                    <Stack>
                                                        <Typography fontFamily={'Forever Grotesk'}
                                                                    variant="body2" color="text.primary">
                                                            {slot.startTime} <br/> {slot.endTime}
                                                        </Typography>
                                                    </Stack>
                                                    <Box sx={{ml: 2}}>
                                                        <Typography variant="body1">
                                                            {slot.description}
                                                        </Typography>
                                                    </Box>
                                                </TimeSlot>
                                                {index < day.timeSlots.length - 1 && <Divider/>}
                                            </>
                                        );
                                    })}
                                </List>
                            </Paper>
                        </AccordionDetails>
                    </StyledAccordion>
                ))}
            </Stack>
        </Box>
    );
};

export default AgendaView;