import {Box, IconButton, Paper, Stack, Typography} from "@mui/material";
import {CollectionsBookmarkOutlined} from "@mui/icons-material";
import pleshDark from "../../theme/pleshDark";

const CustomCard = ({
                        agenda = true,
                        label,
                        icon,
                        body,
                        borderStyle = 'solid',
                        backgroundColor = pleshDark.palette.primary.main,
    children
                    }) => {
    return (
        <Paper elevation={8} sx={{
            boxShadow: `0px 8px 10px 0px rgba(0,0,0,0.2)`,
            //border: `2px ${borderStyle} rgba(0,0,0,0.1)`,
            //borderRadius: `5px`,
            overflowY: 'hidden',
            position: 'relative',
        }}>
            <Stack px={1} py={0.5}
                   direction='row'
                   justifyContent={'end'}
                   alignItems={'center'}
                   spacing={1} sx={{backgroundColor: backgroundColor || pleshDark.palette.primary.main}}
            >
                <Typography textAlign='right'
                            variant='body1'
                            fontWeight={'bold'}
                            color={pleshDark.palette.getContrastText(backgroundColor || pleshDark.palette.primary.main)}
                >
                    {label || "Agenda"}
                </Typography>
              {icon || <CollectionsBookmarkOutlined fontSize='small'/>}
            </Stack>
            <Box minHeight={'70px'} display={'contents'} justifyContent={'flex-start'} alignItems={'center'}>
                <Typography textAlign='center'>
                    {body?.slice(0,100)||''}{body?.length > 100 ? ' ...' : ''}
                </Typography>
                {children}
            </Box>
        </Paper>
    )
}

export default CustomCard;